.Voucher-lineItems{
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
}

.cards-dropdown {
    position: absolute;
    display: inline-block;
    top: 15px;
    right: 15px;
    padding: 5px;
    border-radius: 5px;
}

.cards-dropdown:hover {
    background-color: var(--lite-color);
}
.cards-dropdown .dropdown-content {
    display: none;
    position: absolute;
    z-index: 11;
    right: 0;
    top: 0;
}

.cards-dropdown .dropdown-content .dropdown-items {
    background-color: var(--white-color);
    border-radius: 8px;
    min-width: 130px;
    box-shadow: 2px 3px 7px rgba(0,0,0,0.2);
    padding: 10px 10px;
    font-size: 11px;
    margin-top: 30px;
}

.cards-dropdown .dropdown-content .dropdown-items div{
    padding: 8px 12px;
    border-radius: 5px;
}

.cards-dropdown .dropdown-content .dropdown-items div:hover{
    background-color: var(--primary-color);
    color: #fff;
}

.cards-dropdown:hover .dropdown-content {
    display: block;
}