.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch-mini {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch-mini input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .togglerSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-color);
    -webkit-transition: .4s;
    transition: .4s;
  }

  .togglerSlider-mini {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-color);
    -webkit-transition: .4s;
    transition: .4s;
  }

  .togglerSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white-color) ;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .togglerSlider-mini:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white-color) ;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .togglerSlider {
    background-color: var(--primary-color);
  }

  input:focus + .togglerSlider {
    box-shadow: 0 0 1px var(--primary-color);
  }

  input:checked + .togglerSlider-mini {
    background-color: var(--primary-color);
  }

  input:focus + .togglerSlider-mini {
    box-shadow: 0 0 1px var(--primary-color);
  }

  input:checked + .togglerSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  input:checked + .togglerSlider-mini:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded togglerSliders */
  .togglerSlider.round {
    border-radius: 34px;
  }

  .togglerSlider.round:before {
    border-radius: 50%;
  }

  .togglerSlider-mini.round {
    border-radius: 34px;
  }

  .togglerSlider-mini.round:before {
    border-radius: 50%;
  }
