.file_icon{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
}
.file_icon img{
    width: 40px;
}

.file_name{
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 15px;
}

.tiles{
    width: 200px;
    padding: 1rem;
    margin: 0.7rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    display: flex;
    font-size: 12px;
    position: relative;
}

.tiles:hover{
    cursor: pointer;
    background-color: var(--lite-color);
    border-color: var(--primary-color);
    transition: background 0.3s;

}
.tiles:hover .delete_btn{
    display: block;
}
.delete_btn{
    display: none;
}