.CustomApp-card{
    background-color: var(--lite-color);
    border-radius: 15px;
    padding: 20px;
    margin: 8px;
    font-size: 12px;
    width: 200px;
    color: var(--text-color);
    cursor: pointer;
    border: 1px solid var(--border-color)
}
.CustomApp-tile{
    background-color: var(--lite-color);
    border-radius: 15px;
    padding: 20px;
    margin: 8px;
    font-size: 12px; width: '250px';
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    cursor: pointer;
    border: 1px solid var(--border-color);
    transition: all ease-in 0.15s;
}
.CustomApp-tile:hover{
    background-color: var(--primary-color);
    color: #fff;
}

.CustomApp-card .deploy{
    padding: 10px 15px;
    background: var(--primary-color);
    border-radius: 10px;
    display: inline-flex;
    color: #fff;
    transition: all ease-in 0.15s;
}

.CustomApp-card .deploy:hover{
    background: #111;
}

.mini-loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #767DFF;
    width: 34px;
    height: 34px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
