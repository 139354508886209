.card-solid{
    background-color: #f2f2f2;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0px;
    margin-top: 20px;
}

.card-outline{
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0px;
    margin-top: 20px;
}

.card-outline .title{
    font-size: 12px;
    margin-bottom: 10px;
    color: var(--dark-color);
    text-transform: uppercase;
}

.card-solid .title{
    font-size: 12px;
    margin-bottom: 10px;
    color: var(--dark-color);
    text-transform: uppercase;
}