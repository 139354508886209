.Tabs{
    display: flex;
    align-items: stretch;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0;
    backdrop-filter: blur(10px);
    background: var(--translucent-color);
    z-index: 50;
}

.Tabs .Tabs-active{
   border-radius: 8px;
   padding: 10px 20px;
   margin: 0px 5px 0px 5px;
   background-color: var(--primary-color);
   color: #fff;
   transition: ease-in all 0.15s;
}

.Tabs .Tabs-inactive{
   border-radius: 8px;
   padding: 10px 20px;
   margin: 0px 5px 0px 5px;
   color: var(--muted-text-color);
   transition: ease-in all 0.15s;
}