.progress-point {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    position: relative;
    top: -18px;
  }
.point-done {
    background-color: var(--primary-color);
    color: var(--white-color);
}
.point-done-green {
    color: #0E8F4F;
    border: 3px solid #0E8F4F;
    background:var(--white-color);
    font-weight: 700;
}

.point-dot-active{
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 2px solid var(--primary-color);
    top:-11px;
    cursor: pointer;
    background: var(--white-color) ;
}

.point-dot-active div{
    width: 6px;
    border-radius: 3px;
    height: 6px;
    background: var(--primary-color);
}


.point-dot-green{
    width: 14px;
    height: 14px;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #0E8F4F;
    top:-11px;
    background: var(--white-color) ;
}

.point-dot-green div{
    width: 6px;
    border-radius: 3px;
    height: 6px;
    background: #0E8F4F;
}

.point-dot{
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 2px solid #bbb;
    top:-11px;
    background: var(--white-color) ;
}

.point-dot div{
    width:6px;
    border-radius: 3px;
    height: 6px;
    background: #bbb;
}


.point-active {
    background-color: var(--primary-color);
    color: var(--white-color);
    animation-name: stretch;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
.point-active-two {
    color: var(--primary-color);
    border: 3px solid var(--primary-color);
    background: var(--white-color) ;
    font-weight: 700;

}
.point-active-noPulse {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.blinkIt {
    animation-name: pulsate;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
.singleLine{
    clear: both;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.blinkIt {
    animation-name: pulsate;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes pulsate {
    0% {
        background: #8ba1fa;
    }

    100% {
        background: var(--white-color);
        color: var(--text-color);
    }
}
.blinkIt2 {
    animation-name: pulsate2;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes pulsate2 {
    0% {
        background: #fa6966;
    }

    100% {
        background: var(--white-color);
        color: var(--text-color);
    }
}

@keyframes stretch {
    0% {
        transform: scale(.8);
        border-radius: 100%;
    }

    100% {
        transform: scale(1.2);
    }
}

  .point-inactive {
    background: var(--border-color);
    color: var(--light-color);
    transition: ease-in all 0.3s;
  }
