.Attachment-Thumb:hover .Voucher-attachment:before{
    content: url(./expand.svg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    height: 100%;
    border-radius: 15px;
    background: rgba(0,0,0,0.5);
}
.Attachment-Thumb .delete{
    display:none;
}
.Attachment-Thumb:hover .delete{
    position: absolute; 
    display:flex;
    top: -4px;
    right: 0;
    background-color: var(--white-color);
    border-radius: 50px;
    padding: 3px;
    font-size: 16px;
    cursor: pointer;
}