.button-outline-dark {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--text-color);
    border: 2px solid var(--outline-color);
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.6rem 1rem;
    transition: ease-in all 0.15s;
}

.button-outline-dark:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--force-white);
}


.button-outline-dark.active {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color:var(--force-white);
}

.button-outline-dark-disabled {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--outline-color);
    border: 2px solid var(--outline-color);
    border-radius: 0.5rem;
    padding: 0.6rem 1rem;
    cursor: not-allowed; 
}

.capsule{
    font-size: 0.6rem;
    color: var(--text-color);
    display: inline-flex;
    flex-wrap: wrap;
    white-space: nowrap;
    margin-top: 0.5rem;
    background: var(--off-white);
    margin: 0.2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 50px;
    border: 1px solid var(--border-color);
    z-index: 10;
}
