.timeline {
  display: flex;
  background: var(--white-color);
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 1.5rem 0.5rem;
  margin: auto;
}

.timeline .track {
  width: 5px;
  background-color: var(--border-color);
  position: relative;
  border-radius: 50px;
}

.timeline .track-fill {
  width: 5px;
  background-color: var(--blue-color);
  position: relative;
  left: -5px;
  border-radius: 50px;
  transition:  ease-in height 0.3s;
}

.timeline .stage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  left: -17px;
}

.timeline .track-point {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline .pending {
  background: #cccc00;
  min-width: 20px;
  min-height: 20px;
  border-radius: 30px;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}
.timeline .point {
  background: var(--blue-color);
  min-width: 20px;
  min-height: 20px;
  border-radius: 30px;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.timeline .point-pending {
  background: var(--border-color);
  min-width: 20px;
  min-height: 20px;
  border-radius: 30px;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.timeline .point-active {
    background: var(--green-color);
    width: 20px;
    height: 20px;
    border-radius: 30px;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    animation-name: stretch;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes stretch {
    0% {
        transform: scale(.6);
        border-radius: 100%;
    }

    100% {
        transform: scale(1);
    }
}

.timeline .point-text {
  text-align: left;
  font-size: 0.7rem;
  padding: 0.8rem;
  background:  var(--off-white);
  border-radius: 0.5rem;
  margin: 1rem 1rem;
}
