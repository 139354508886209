#theme.dark {
     background: #111111;
    --off-white: #111111;
    --white-color: #222222;
    --sidebar-color: #222222;
    --primary-color: #767DFF;
    --red-color: #FF6464;
    --success-color: #38ada9;
    --text-color: #ffffff;
    --border-color: #333;
    --force-white:#fff;
    --light-color: #eeeeee;
    --blue-color:#3e8fdc;
    --lite-color: #333;
    --green-color:#198754;
    --outline-color: #222222;
}

#theme.light{
    background: #F9F9FA;
    --white-color: #ffffff;
    --sidebar-color: #F9F9FA;
    --off-white: #F9F9FA;
    --primary-color: #767DFF;
    --red-color: #FF6464;
    --blue-color:#3e8fdc;
    --success-color: #38ada9;
    --text-color: #26273C;
    --light-color: #555555;
    --lite-color: #f2f2f2;
    --border-color: #efefef;
    --green-color:#198754;
    --force-white:#fff;
    --outline-color: #d2d2d2;
}

body{
    --font-family:"Roboto";
}

#__react-alert__ span{
    color: var(--force-white) !important;
}
