.container {
    flex: 1;
    display: flex;
    flex-direction: row-reverse
}

.content-data {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 0 15px;
}

.content-img {
    position: relative;
    flex: 1;
    width: 40%;
    overflow: hidden;
}

.row {
    display: flex;
    margin-bottom: 10px;
}

.column {
    flex: 1;
}

label {
    color: rgb(107, 114, 128);
    font-size: 13px;
    margin-right: 10px;
}

.keyValue {
    display: flex;
    gap: 5px;
    align-items: center;
}

label::after {
    content: ':';
}

input[type="checkbox"] {
    accent-color: var(--theme-primary-color);
    cursor: pointer;
    height: 14px !important;
    width: 14px !important;
}

.keyValue>div {
    font-weight: 500;
    font-size: 1rem;
}

.mr1 {
    margin-right: 5px;
}

.align-center {
    align-items: center;
}

footer{
    margin-top: 1rem;
    gap: 10px;
    justify-content: flex-end;
}



.chip-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 equal columns */
    gap: 8px;
    width: 100%;
}

.chip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 16px;
    padding: 8px 12px;
    text-align: center;
    min-height: 60px;
}

/* If key includes "address", span 2 columns */
.chip-wide {
    grid-column: span 2;
}
.chip-key {
    font-size: 11px;
    color: #555;
}

.chip-value {
    font-size: 14px;
    font-weight: bold;
}