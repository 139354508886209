.invoice-taxes{
    display: flex;
    justify-content: flex-end;
}

.invoice-taxes .wrap{
    display: flex;
    padding: 10px 0px;
    border-top: 1px solid var(--border-color)
}

.invoice-taxes .wrap div{
    width: 100px
}

.invoice-taxes .wrap > :first-child{
    text-align: left;
}

.invoice-taxes .wrap > :last-child{
    text-align: right;
}
