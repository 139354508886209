
.marketplace .tabs-sidebar{
    width: 150px;
}

.marketplace .tabs-sidebar .option{
    background-color: var(--white-color);
    padding: 0.6rem 1.4rem;
    box-shadow: 0rem 0.2rem 0.5rem rgba(1,1,1,.06)!important;
    cursor: pointer;
    transition: ease-in box-shadow 0.15s;
    color: var(--text-color);
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 25px;
    min-width: 150px;
    transition: ease-in all 0.15s;
    border-Top: 1px solid var(--border-color)
}

.marketplace .tabs-sidebar .option:hover{
    background-color: var(--primary-color);
    color: #fff;
}

.marketplace .tabs-sidebar .option-active{
    background-color: var(--primary-color);
    padding: 0.6rem 1.4rem;
    box-shadow: 0rem 0.2rem 0.5rem rgba(1,1,1,.06)!important;
    cursor: pointer;
    transition: ease-in box-shadow 0.15s;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 25px;
    min-width: 150px;
    transition: ease-in all 0.15s;
    border-Top: 1px solid var(--border-color)
}

.search-box{
    width:100%;
    max-width: 400px;
    border: 1px solid var(--force-white);
    background: var(--force-white);
    border-radius: 8px;
    padding: 15px 15px;
    transition: ease-in all 0.2s;
}
.search-box:focus{
    max-width: 600px;
}